
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class ModelCard extends Vue {
  @Prop({ default: null })
  public card!: any;
  @Prop({ default: false })
  public editable!: boolean;
  @Prop({ default: false })
  public main!: boolean;
  @Prop({ default: false })
  public publicbutton!: boolean;
  @Prop({ default: false })
  public loading!: boolean;
  // Editable, if we show delete menu etc
  // main. If we want it to look like main model page or dataset view (false)
  // public. If we want the copy to wroskpace button (public model)

  public async mounted() {}

  public capitalizeFirstLetter(inputString: string): string {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public dateParse(ts) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY h:mm A");
  }

  public removeModel(id) {
    this.remove(id, true);
  }

  public getExportPrice(id) {
    this.exportprice(id);
  }

  public addToWorkspace(id) {
    this.addtoworkspace(id);
  }

  public statusCheck(model) {
    // returns true if status indicates training/uploading etc
    if (model?.status === "deployed" || model?.status === "ready") {
      if (model?.label_containers[0].status === "ready") {
        return false;
      }
      return true;
    }
    return true;
  }

  @Emit()
  public remove(id, dialogRemove) {}

  @Emit()
  public exportprice(id) {}

  @Emit()
  public addtoworkspace(id) {}
}
